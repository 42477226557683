import React, { useEffect, useState } from "react";
import Globe from "react-globe.gl";
import Button from "../components/Button";
import { motion } from "framer-motion";

const About = () => {
  const [hasCopied, setHasCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText("jonasdv.sevillano@gmail.com");
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  return (
    <motion.div
      id="about"
      className="c-space my-20"
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: -100 }}
      transition={{ duration: 1, delay: 0.2, ease: "easeInOut" }}
      viewport={{ once: true }}
    >
      <div className="max-w-[1440px] mx-auto grid xl:grid-cols-3 xl:grid-rows-6 md:grid-cols-2 grid-cols-1 gap-5 h-full">
        <div className="col-span-1 xl:row-span-3">
          <div className="grid-container">
            <img
              src="/assets/grid1.png"
              alt="grid-1"
              className="size-52 h-fit object-cover rounded-full mx-auto"
            />
            <div>
              <p className="grid-headtext">Hi, I am Jonas</p>
              <p className="grid-subtext">
                As a Computer Science graduate With one and a half year of
                working experience, I have honed enough skills in frontend and
                backend development that can make an impact to your business and
                turn your ideas into reality. I am also expanding my horizons to
                make the most of my degree, strengthening my data science skills
                to keep up with the rapid growth of AI in the industry.
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-1 xl:row-span-3">
          <div className="grid-container flex justify-between">
            <div className="py-20 flex flex-row gap-3 w-full lg:w-6/12 flex-wrap ">
              <img src="/assets/react.svg" className="size-14" />
              <img src="/assets/nextjs.svg" className="size-14" />
              <img src="/assets/tailwindcss.png" className="size-14" />
              <img src="/assets/javascript.svg" className="size-10" />
              <img src="/assets/nodejs.svg" className="size-10" />
              <img src="/assets/typescript.png" className="size-10" />

              <img src="/assets/python.svg" className="size-16" />
              <img src="/assets/framer.png" className="size-12" />

              <img src="/assets/mongodb.svg" className="size-12" />
              <img src="/assets/java.svg" className="w-12 h-16" />
              <img src="/assets/spring_boot.svg" className="size-12" />
            </div>
            <div>
              <p className="grid-headtext">Tech Stack</p>
              <p className="grid-subtext">
                I am experienced with using Python such as FastAPI and has
                strong knowledge of core and advanced Java concepts. I am also
                Building fast and scalable servers using Spring Boot framework
                to excel more in Java focused roles. Furthermore, I am also
                proficient with TypeScript and Javascript with it's front-end
                and full stack frameworks and I am currently specializing in
                JavaScript with focus on React.js and Next.js.
              </p>
            </div>
          </div>{" "}
        </div>
        <div className="col-span-1 xl:row-span-4">
          <div className="grid-container">
            <div className="rounded-3xl w-full sm:h-[326px] h-fit flex justify-center items-center">
              <Globe
                height={326}
                width={326}
                backgroundColor="rgba(0,0,0,0)"
                backgroundImageOpacity={0.5}
                showAtmosphere
                showGraticules
                globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
                labelsData={[
                  {
                    lat: 14.320719,
                    lng: 120.936485,
                    text: "I'm here!",
                    color: "white",
                    size: 20,
                  },
                ]}
              />
            </div>
            <div>
              <p className="grid-headtext">I work remotely</p>
              <p className="grid-subtext">
                I'm based in Philippines, with remote work available
              </p>
              <a href="#contact" className="w-fit">
                <Button
                  name={"Contact Me"}
                  isBeam
                  containerClass={"w-full mt-10"}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="xl:col-span-2 xl:row-span-3">
          <div className="grid-container">
            <img
              src={"/assets/grid3.png"}
              alt="grid-3"
              className="w-full sm:h-[266px] h-fit object-contain"
            />
            <div>
              <p className="grid-headtext">My Passion for Coding</p>
              <p className="grid-subtext">
                I like solving problems and programming allows me to solve
                problems by building projects as solution, I've always been
                amazed by how application works just by typing words to
                communicate with the machine.
              </p>
            </div>
          </div>
        </div>
        <div className="xl:col-span-1 xl:row-span-2">
          <div className="grid-container">
            <img
              src="/assets/grid4.png"
              alt="grid4"
              className="w-full md:h-[126px] sm:h-[276px] h-fit object-cover sm:object-top"
            />
            <div className="space-y-2">
              <p className="grid-subtext text-center">Contact Me</p>
              <div className="copy-container" onClick={handleCopy}>
                <img
                  src={hasCopied ? "/assets/tick.svg" : "/assets/copy.svg"}
                  alt="copy"
                />
                <p className="lg:text-2xl md:text-xl font-medium text-gray_gradient text-white">
                  jonasdv.sevillano@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
